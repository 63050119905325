:root{
    --react-algeria-map-hover-fill : white;
    --react-algeria-map-hover-stroke : dodgerblue;
  }
  .state {
    transition: 1s;
  }
  :hover.state {
    fill: var(--react-algeria-map-hover-fill,white);
    stroke: var(--react-algeria-map-hover-stroke,dodgerblue);
    stroke-width: 4px;
    cursor: pointer;
  }


#videoContainer {
  width: "100%";

  height: "100%";
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #bbdce9;
  overflow: scroll;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  touch-action: none;
}


#zoom {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
  user-select: none;
  touch-action: none;

}
#videoContainer::-webkit-scrollbar {
  display: none; /* for Chrome, Safari and Opera */
}

#videoContainer{
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}